import {
  addActionHandler, getActions, getGlobal, setGlobal,
} from '../../index';

import type {
  ApiChat, ApiUser, ApiChatFolder, ApiError, ApiChatMember,
} from '../../../api/types';
import { MAIN_THREAD_ID } from '../../../api/types';
import { NewChatMembersProgress, ChatCreationProgress, ManagementProgress } from '../../../types';
import type { GlobalActions, GlobalState } from '../../types';

import {
  ARCHIVED_FOLDER_ID,
  TOP_CHAT_MESSAGES_PRELOAD_LIMIT,
  CHAT_LIST_LOAD_SLICE,
  RE_TG_LINK,
  SERVICE_NOTIFICATIONS_USER_ID,
  TMP_CHAT_ID,
  ALL_FOLDER_ID,
  DEBUG,
} from '../../../config';
import { callApi } from '../../../api/gramjs';
import {
  addChats, addUsers, addUserStatuses, replaceThreadParam,
  updateChatListIds, updateChats, updateChat, updateChatListSecondaryInfo,
  updateManagementProgress, leaveChat, replaceUsers, replaceUserStatuses,
  replaceChats, replaceChatListIds, addChatMembers, updateUser, updateTabState,
} from '../../reducers';
import {
  selectChat, selectUser, selectChatListType, selectIsChatPinned,
  selectChatFolder, selectSupportChat, selectChatByUsername, selectThreadTopMessageId,
  selectCurrentMessageList, selectThreadInfo, selectCurrentChat, selectLastServiceNotification,
  selectVisibleUsers, selectUserByPhoneNumber, selectDraft,
} from '../../selectors';
import { buildCollectionByKey, omit } from '../../../util/iteratees';
import { debounce, pause, throttle } from '../../../util/schedulers';
import {
  isChatSummaryOnly,
  isChatArchived,
  isChatBasicGroup,
  isChatChannel,
  isChatSuperGroup,
  isUserBot,
} from '../../helpers';
import { formatShareText, parseChooseParameter, processDeepLink } from '../../../util/deeplink';
import { updateGroupCall } from '../../reducers/calls';
import { selectGroupCall } from '../../selectors/calls';
import { getOrderedIds } from '../../../util/folderManager';
import * as langProvider from '../../../util/langProvider';
import { selectCurrentLimit } from '../../selectors/limits';
import { countSlashes, Toast } from '../../../util/toolUtils';
import { getCurrentTabId } from '../../../util/establishMultitabRole';
import useLang from '../../../hooks/useLang';

const TOP_CHAT_MESSAGES_PRELOAD_INTERVAL = 100;
const INFINITE_LOOP_MARKER = 100;

const SERVICE_NOTIFICATIONS_USER_MOCK: ApiUser = {
  id: SERVICE_NOTIFICATIONS_USER_ID,
  accessHash: '0',
  type: 'userTypeRegular',
  isMin: true,
  username: '',
  phoneNumber: '',
};

const runThrottledForLoadTopChats = throttle((cb) => cb(), 3000, true);
const runDebouncedForLoadFullChat = debounce((cb) => cb(), 500, false, true);

// 预加载顶部聊天消息
addActionHandler('preloadTopChatMessages', async (global, actions) => {
  const preloadedChatIds = new Set<string>();

  for (let i = 0; i < TOP_CHAT_MESSAGES_PRELOAD_LIMIT; i++) {
    await pause(TOP_CHAT_MESSAGES_PRELOAD_INTERVAL);

    const { chatId: currentChatId } = selectCurrentMessageList(global) || {};
    const folderAllOrderedIds = getOrderedIds(ALL_FOLDER_ID);
    const nextChatId = folderAllOrderedIds?.find((id) => id !== currentChatId && !preloadedChatIds.has(id));
    if (!nextChatId) {
      return;
    }

    preloadedChatIds.add(nextChatId);

    actions.loadViewportMessages({ chatId: nextChatId, threadId: MAIN_THREAD_ID });
  }
});

addActionHandler('openChat', (global, actions, payload) => {
  // debugger
  const { id, threadId = MAIN_THREAD_ID } = payload;
  if (!id) {
    return;
  }

  const { currentUserId } = global;
  const chat = selectChat(global, id);

  if (chat?.hasUnreadMark) {
    actions.toggleChatUnread({ id });
  }

  // Please telegram send us some updates about linked chat 🙏
  if (chat?.lastMessage?.threadInfo) {
    actions.requestThreadInfoUpdate({
      chatId: chat.lastMessage.threadInfo.chatId,
      threadId: chat.lastMessage.threadInfo.threadId,
    });
  }

  if (!chat) {
    if (id === currentUserId) {
      void callApi('fetchChat', { type: 'self' });
    } else {
      const user = selectUser(global, id);
      if (user) {
        void callApi('fetchChat', { type: 'user', user });
      }
    }
  } else if (isChatSummaryOnly(chat) && !chat.isMin) {
    actions.requestChatUpdate({ chatId: id });
  }

  if (threadId !== MAIN_THREAD_ID) {
    const topMessageId = selectThreadTopMessageId(global, id, threadId);
    if (!topMessageId) {
      actions.requestThreadInfoUpdate({ chatId: id, threadId });
    }
  }
});

addActionHandler('openLinkedChat', async (global, actions, payload) => {
  const { id } = payload!;
  const chat = selectChat(global, id);
  if (!chat) {
    return;
  }

  const chatFullInfo = await callApi('fetchFullChat', chat);

  if (chatFullInfo?.fullInfo?.linkedChatId) {
    actions.openChat({ id: chatFullInfo.fullInfo.linkedChatId });
  }
});

addActionHandler('focusMessageInComments', async (global, actions, payload) => {
  const { chatId, threadId, messageId } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  const result = await callApi('requestThreadInfoUpdate', { chat, threadId });
  if (!result) {
    return;
  }

  actions.focusMessage({ chatId, threadId, messageId });
});

// 打开支持会话
addActionHandler('openSupportChat', async (global, actions) => {
  const chat = selectSupportChat(global);
  if (chat) {
    actions.openChat({ id: chat.id, shouldReplaceHistory: true });
    return;
  }

  actions.openChat({ id: TMP_CHAT_ID, shouldReplaceHistory: true });

  const result = await callApi('fetchChat', { type: 'support' });
  if (result) {
    actions.openChat({ id: result.chatId, shouldReplaceHistory: true });
  }
});

// 加载所有会话
addActionHandler('loadAllChats', async (global, actions, payload) => {
  const listType = payload.listType as 'active' | 'archived';
  const { onReplace } = payload;
  let { shouldReplace } = payload;
  let i = 0;
  const getOrderDate = (chat: ApiChat) => {
    return chat.lastMessage?.date || chat.joinDate;
  };

  while (shouldReplace || !getGlobal().chats.isFullyLoaded[listType]) {
    if (i++ >= INFINITE_LOOP_MARKER) {
      if (DEBUG) {
        // eslint-disable-next-line no-console
        console.error('`actions/loadAllChats`: Infinite loop detected');
      }

      return;
    }

    global = getGlobal();
    if (global.connectionState !== 'connectionStateReady' || global.authState !== 'authorizationStateReady') {
      return;
    }

    const listIds = !shouldReplace && global.chats.listIds[listType];
    const oldestChat = listIds
      ? listIds
        /* eslint-disable @typescript-eslint/no-loop-func */
        .map((id) => global.chats.byId[id])
        .filter((chat) => (
          Boolean(chat && getOrderDate(chat))
          && chat.id !== SERVICE_NOTIFICATIONS_USER_ID
          && !selectIsChatPinned(global, chat.id)
        ))
        /* eslint-enable @typescript-eslint/no-loop-func */
        .sort((chat1, chat2) => getOrderDate(chat1)! - getOrderDate(chat2)!)[0]
      : undefined;

    await loadChats(listType, oldestChat?.id, oldestChat ? getOrderDate(oldestChat) : undefined, shouldReplace, true);

    if (shouldReplace) {
      onReplace?.();
      shouldReplace = false;
    }
  }
});
// 登录获取权限
addActionHandler('getAllPrivce', async () => {
  const res = await callApi('getAllPrivce');
  const temp: number[] = [];
  res?.forEach((item) => {
    if (item.key === 11) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.createGroup = true;
      } else {
        window.userRights.createGroup = false;
      }
      temp.push(1);
    } else if (item.key === 12) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.createChannel = true;
      } else {
        window.userRights.createChannel = false;
      }
      temp.push(2);
    } else if (item.key === 13) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.addContact = true;
      } else {
        window.userRights.addContact = false;
      }
      temp.push(3);
    } else if (item.key === 14) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.showMember = true;
      } else {
        window.userRights.showMember = false;
      }
      temp.push(4);
    } else if (item.key === 15) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.uploadPhoto = true;
      } else {
        window.userRights.uploadPhoto = false;
      }
    } else if (item.key === 16) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.uploadFile = true;
      } else {
        window.userRights.uploadFile = false;
      }
    } else if (item.key === 17) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.folder = true;
      } else {
        window.userRights.folder = false;
      }
    }else if(item.key === 18) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.privacy = true;
      } else {
        window.userRights.privacy = false;
      }
    }else if(item.key === 19) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.blockpeers = true;
      } else {
        window.userRights.blockpeers = false;
      }
    }else if(item.key === 20) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.modifyprivacies = true;
      } else {
        window.userRights.modifyprivacies = false;
      }
    }else if(item.key === 21) {
      if (item.rule.className === 'PrivacyValueAllowAll') {
        window.userRights.username = true;
      } else {
        window.userRights.username = false;
      }
    }
  });
  if (temp.length < 4) {
    if (!temp.includes(1)) {
      window.userRights.createGroup = true;
    }
    if (!temp.includes(2)) {
      window.userRights.createChannel = true;
    }
    if (!temp.includes(3)) {
      window.userRights.addContact = true;
    }
    if (!temp.includes(4)) {
      window.userRights.showMember = true;
    }
  }
  setGlobal({
    ...getGlobal(),
    userRights: window.userRights,
  });
});

addActionHandler('loadFullChat', (global, actions, payload) => {
  const { chatId, force } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  if (force) {
    loadFullChat(chat);
  } else {
    runDebouncedForLoadFullChat(() => loadFullChat(chat));
  }
});

// 加载顶部会话
addActionHandler('loadTopChats', () => {
  runThrottledForLoadTopChats(() => loadChats('active'));
});

// 请求会话更新
addActionHandler('requestChatUpdate', (global, actions, payload) => {
  const { serverTimeOffset } = global;
  const { chatId } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  void callApi('requestChatUpdate', {
    chat,
    serverTimeOffset,
    ...(chatId === SERVICE_NOTIFICATIONS_USER_ID && {
      lastLocalMessage: selectLastServiceNotification(global)?.message,
    }),
  });
});

addActionHandler('updateChatMutedState', (global, actions, payload) => {
  const { serverTimeOffset } = global;
  const { chatId, isMuted } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  setGlobal(updateChat(global, chatId, { isMuted }));
  void callApi('updateChatMutedState', { chat, isMuted, serverTimeOffset });
});

// 创建频道
addActionHandler('createChannel', (global, actions, payload) => {
  const {
    title, about, photo, memberIds,
  } = payload!;

  const members = (memberIds as string[])
    .map((id) => selectUser(global, id))
    .filter(Boolean);

  void createChannel(title, members, about, photo);
});

// 加入频道
addActionHandler('joinChannel', (global, actions, payload) => {
  const { chatId } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  const { id: channelId, accessHash } = chat;

  if (!(channelId && accessHash)) {
    return;
  }

  void joinChannel(channelId, accessHash);
});

// 删除会话用户
addActionHandler('deleteChatUser', (global, actions, payload) => {
  // debugger
  const { chatId, userId }: { chatId: string; userId: string } = payload!;
  const chat = selectChat(global, chatId);
  const user = selectUser(global, userId);
  if (!chat || !user) {
    return;
  }

  global = leaveChat(global, chatId);
  setGlobal(global);

  if (selectCurrentMessageList(global)?.chatId === chatId) {
    actions.openChat({ id: undefined });
  }

  void callApi('deleteChatUser', { chat, user });
});

// 删除会话
addActionHandler('deleteChat', (global, actions, payload) => {
  const { chatId }: { chatId: string } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  global = leaveChat(global, chatId);
  setGlobal(global);

  const activeChat = selectCurrentMessageList(global);
  if (activeChat && activeChat.chatId === chatId) {
    actions.openChat({ id: undefined });
  }

  void callApi('deleteChat', { chatId: chat.id });
});

// 离开频道
addActionHandler('leaveChannel', (global, actions, payload) => {
  const { chatId } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  global = leaveChat(global, chatId);
  setGlobal(global);

  if (selectCurrentMessageList(global)?.chatId === chatId) {
    actions.openChat({ id: undefined });
  }

  const { id: channelId, accessHash } = chat;
  if (channelId && accessHash) {
    void callApi('leaveChannel', { channelId, accessHash });
  }
});

// 删除频道
addActionHandler('deleteChannel', (global, actions, payload) => {
  const { chatId } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  global = leaveChat(global, chatId);
  setGlobal(global);

  if (selectCurrentMessageList(global)?.chatId === chatId) {
    actions.openChat({ id: undefined });
  }

  const { id: channelId, accessHash } = chat;
  if (channelId && accessHash) {
    void callApi('deleteChannel', { channelId, accessHash });
  }
});

// 创建群会话
addActionHandler('createGroupChat', (global, actions, payload) => {
  const { title, memberIds, photo } = payload!;
  const members = (memberIds as string[])
    .map((id) => selectUser(global, id))
    .filter(Boolean);

  void createGroupChat(title, members, photo);
});

// 切换固定会话
addActionHandler('toggleChatPinned', (global, actions, payload) => {
  const { id, folderId } = payload!;
  const chat = selectChat(global, id);
  if (!chat) {
    return;
  }

  const limit = selectCurrentLimit(global, 'dialogFolderPinned');

  if (folderId) {
    const folder = selectChatFolder(global, folderId);
    if (folder) {
      const shouldBePinned = !selectIsChatPinned(global, id, folderId);

      const { pinnedChatIds, includedChatIds } = folder;
      const newPinnedIds = shouldBePinned
        ? [id, ...(pinnedChatIds || [])]
        : (pinnedChatIds || []).filter((pinnedId) => pinnedId !== id);

      // With both Pin and Unpin we need to re-add a user to the included group
      const newIncludedChatIds = [...includedChatIds]
      // [id, ...includedChatIds];
      void callApi('editChatFolder', {
        id: folderId,
        folderUpdate: {
          ...folder,
          pinnedChatIds: newPinnedIds,
          includedChatIds: newIncludedChatIds,
        },
      });
    }
  } else {
    const listType = selectChatListType(global, id);
    const isPinned = selectIsChatPinned(global, id, listType === 'archived' ? ARCHIVED_FOLDER_ID : undefined);

    const ids = global.chats.orderedPinnedIds[listType === 'archived' ? 'archived' : 'active'];
    if ((ids?.length || 0) >= limit && !isPinned) {
      actions.openLimitReachedModal({
        limit: 'dialogFolderPinned',
      });
      return;
    }
    void callApi('toggleChatPinned', { chat, shouldBePinned: !isPinned });
  }
});

// 删除会话
addActionHandler('chatFolderDelete', (global, actions, payload) => {
  const { id, folderId } = payload!;
  const chat = selectChat(global, id);
  if (!chat) {
    return;
  }

  if (folderId) {
    const folder = selectChatFolder(global, folderId);
    if (folder) {
      const shouldBePinned = !selectIsChatPinned(global, id, folderId);

      const { pinnedChatIds, includedChatIds } = folder;
      const newPinnedIds = shouldBePinned
        ? [id, ...(pinnedChatIds || [])]
        : (pinnedChatIds || []).filter((pinnedId) => pinnedId !== id);

      // With both Pin and Unpin we need to re-add a user to the included group
      const newIncludedChatIds = (includedChatIds || []).filter((pinnedId) => pinnedId !== id);
      // const newIncludedChatIds = [...includedChatIds]
      // [id, ...includedChatIds];
      void callApi('editChatFolder', {
        id: folderId,
        folderUpdate: {
          ...folder,
          pinnedChatIds: newPinnedIds,
          includedChatIds: newIncludedChatIds,
        },
      });
    }
  }

});
addActionHandler('chatPinned', (global, actions, payload) => {
  const { id, accessHash, shouldBePinned } = payload!;
  const limit = selectCurrentLimit(global, 'dialogFolderPinned');
  const listType = selectChatListType(global, id);
  const isPinned = selectIsChatPinned(global, id, listType === 'archived' ? ARCHIVED_FOLDER_ID : undefined);

  const ids = global.chats.orderedPinnedIds[listType === 'archived' ? 'archived' : 'active'];
  if ((ids?.length || 0) >= limit && !isPinned) {
    actions.openLimitReachedModal({
      limit: 'dialogFolderPinned',
    });
    return;
  }
  void callApi('chatPinned', { id, accessHash, shouldBePinned });
});
// 切换存档会话
addActionHandler('toggleChatArchived', (global, actions, payload) => {
  const { id } = payload!;
  const chat = selectChat(global, id);
  if (chat) {
    void callApi('toggleChatArchived', {
      chat,
      folderId: isChatArchived(chat) ? 0 : ARCHIVED_FOLDER_ID,
    });
  }
});

// 加载所有会话文件夹
addActionHandler('loadChatFolders', () => {
  void loadChatFolders();
});

// 加载所有推荐会话文件夹
addActionHandler('loadRecommendedChatFolders', () => {
  void loadRecommendedChatFolders();
});

// 修改所有会话文件夹
addActionHandler('editChatFolders', (global, actions, payload) => {
  const { chatId, idsToRemove, idsToAdd } = payload!;
  const limit = selectCurrentLimit(global, 'dialogFiltersChats');

  const isLimitReached = (idsToAdd as number[])
    .some((id) => selectChatFolder(global, id)!.includedChatIds.length >= limit);
  if (isLimitReached) {
    actions.openLimitReachedModal({ limit: 'dialogFiltersChats' });
    return;
  }

  (idsToRemove as number[]).forEach(async (id) => {
    const folder = selectChatFolder(global, id);
    if (folder) {
      await callApi('editChatFolder', {
        id,
        folderUpdate: {
          ...folder,
          pinnedChatIds: folder.pinnedChatIds?.filter((pinnedId) => pinnedId !== chatId),
          includedChatIds: folder.includedChatIds.filter((includedId) => includedId !== chatId),
        },
      });
    }
  });

  (idsToAdd as number[]).forEach(async (id) => {
    const folder = selectChatFolder(global, id);
    if (folder) {
      await callApi('editChatFolder', {
        id,
        folderUpdate: {
          ...folder,
          includedChatIds: folder.includedChatIds.concat(chatId),
        },
      });
    }
  });
});

// 修改单个会话文件
addActionHandler('editChatFolder', (global, actions, payload) => {
  const { id, folderUpdate } = payload!;
  const { includedChatIds } = folderUpdate;
  const folder = selectChatFolder(global, id);
  const chatsMap = {};
  if (includedChatIds && includedChatIds.length > 0) {
    for (let i = 0; i < includedChatIds.length; i++) {
      chatsMap[includedChatIds[i]] = selectChat(global, includedChatIds[i]);
    }
  }
  if (folder) {
    void callApi('editChatFolder', {
      id,
      folderUpdate: {
        id,
        emoticon: folder.emoticon,
        pinnedChatIds: folder.pinnedChatIds,
        chatsMap,
        ...folderUpdate,
      },
    });
  }
});

// 添加单个会话文件夹
addActionHandler('addChatFolder', async (global, actions, payload) => {
  const { folder, tabId = getCurrentTabId() } = payload!;
  const { includedChatIds } = folder;
  const { orderedIds, byId } = global.chatFolders;
  const limit = selectCurrentLimit(global, 'dialogFilters');
  const chatsMap = {};
  if (includedChatIds && includedChatIds.length > 0) {
    for (let i = 0; i < includedChatIds.length; i++) {
      chatsMap[includedChatIds[i]] = selectChat(global, includedChatIds[i]);
    }
  }
  if (Object.keys(byId).length >= limit) {
    actions.openLimitReachedModal({
      limit: 'dialogFilters',
    });
    return;
  }

  const maxId = Math.max(...(orderedIds || []), ARCHIVED_FOLDER_ID);
  void createChatFolder(folder, maxId, actions, chatsMap);
});
// 对所有会话文件夹排序
addActionHandler('sortChatFolders', async (global, actions, payload) => {
  const { folderIds } = payload!;

  const result = await callApi('sortChatFolders', folderIds);
  if (result) {
    global = getGlobal();
    setGlobal({
      ...global,
      chatFolders: {
        ...global.chatFolders,
        orderedIds: folderIds,
      },
    });
  }
});

// 删除单个会话文件夹
addActionHandler('deleteChatFolder', (global, actions, payload) => {
  const { id } = payload!;
  const folder = selectChatFolder(global, id);

  if (folder) {
    void deleteChatFolder(id);
  }
});

// 切换未读会话
addActionHandler('toggleChatUnread', (global, actions, payload) => {
  const { id } = payload!;
  const { serverTimeOffset } = global;
  const chat = selectChat(global, id);
  if (chat) {
    if (chat.unreadCount) {
      void callApi('markMessageListRead', { serverTimeOffset, chat, threadId: MAIN_THREAD_ID });
    } else {
      void callApi('toggleDialogUnread', {
        chat,
        hasUnreadMark: !chat.hasUnreadMark,
      });
    }
  }
});

// 通过邀请打开会话
addActionHandler('openChatByInvite', async (global, actions, payload) => {
  const { hash } = payload!;

  const result = await callApi('openChatByInvite', hash);
  if (!result) {
    return;
  }

  actions.openChat({ id: result.chatId });
});

// 通过手机号打开会话
addActionHandler('openChatByPhoneNumber', async (global, actions, payload) => {
  const { phoneNumber, startAttach, attach } = payload!;

  // Open temporary empty chat to make the click response feel faster
  actions.openChat({ id: TMP_CHAT_ID });

  const chat = await fetchChatByPhoneNumber(phoneNumber);
  if (!chat) {
    actions.openPreviousChat();
    actions.showNotification({
      message: langProvider.getTranslation('lng_username_by_phone_not_found').replace('{phone}', phoneNumber),
    });
    return;
  }

  actions.openChat({ id: chat.id });

  if (attach) {
    openAttachMenuFromLink(actions, chat.id, attach, startAttach);
  }
});

// 通过tg链接打开会话
addActionHandler('openTelegramLink', (global, actions, payload) => {
  const { url, fir } = payload!;
  const {
    openChatByPhoneNumber,
    openChatByInvite,
    openStickerSet,
    openChatWithDraft,
    joinVoiceChatByLink,
    showNotification,
    focusMessage,
    openInvoice,
    processAttachBotParameters,
    openChatByUsername: openChatByUsernameAction,
  } = actions;

  if (url.match(RE_TG_LINK)) {
    processDeepLink(url);
    return;
  }
  // t.me
  const uri = new URL(url.startsWith('http') ? url : `https://${url}`);
  if ((uri.hostname === 'tg.cc' || uri.hostname === 'metanet.house') && uri.pathname === '/') {
    window.open(uri.toString(), '_blank', 'noopener');
    return;
  }

  const hostParts = uri.hostname.split('.');
  if (hostParts.length > 4) {
    // if(customConfig && customConfig.link && url.indexOf(customConfig.link) > -1){
    //   const pathname = hostParts.length === 3 ? `${hostParts[0]}/${uri.pathname}` : uri.pathname;
    //   const [part1, part2, part3] = pathname.split('/').filter(Boolean).map((l) => decodeURI(l));
    //   const params = Object.fromEntries(uri.searchParams);
    // }
    return;
  }
  let pathname = '';
  if (hostParts.length <= 3) {
    pathname = `${hostParts[0]}/${uri.pathname}`;
  }
  if (hostParts.length > 3) {
    const tempPath = uri.pathname;
    if (countSlashes(tempPath) > 1) {
      pathname = tempPath.substring(tempPath.lastIndexOf('/'), tempPath.length);
    } else {
      pathname = tempPath;
    }
  }
  // const pathname = hostParts.length === 3 ? `${hostParts[0]}/${uri.pathname}` : uri.pathname;
  const [part1, part2, part3] = pathname.split('/').filter(Boolean).map((l) => decodeURI(l));
  const params = Object.fromEntries(uri.searchParams);

  let hash: string | undefined;
  if (part1 === 'joinchat') {
    hash = part2;
  }
  const startAttach = params.hasOwnProperty('startattach') && !params.startattach ? true : params.startattach;
  const choose = parseChooseParameter(params.choose);

  if (part1.match(/^\+([0-9]+)(\?|$)/)) {
    openChatByPhoneNumber({
      phoneNumber: part1.substr(1, part1.length - 1),
      startAttach,
      attach: params.attach,
    });
    return;
  }

  if (part1.startsWith(' ') || part1.startsWith('+')) {
    hash = part1.substr(1, part1.length - 1);
  }
  if (uri.hostname.indexOf(part1) > -1) {
    if (part2 && part2.startsWith(' ') || part2 && part2.startsWith('+')) {
      hash = part2.substr(1, part2.length - 1);
    }
  }
  if (hash) {
    openChatByInvite({ hash });
    return;
  }

  if (part1 === 'addstickers' || part1 === 'addemoji') {
    openStickerSet({
      stickerSetInfo: {
        shortName: part2,
      },
    });
    return;
  }

  const chatOrChannelPostId = part2 || undefined;
  const messageId = part3 ? Number(part3) : undefined;
  const commentId = params.comment ? Number(params.comment) : undefined;

  if (part1 === 'share') {
    const text = formatShareText(params.url, params.text);
    openChatWithDraft({ text });
  } else if (params.hasOwnProperty('voicechat') || params.hasOwnProperty('livestream')) {
    // 通过链接加入语音聊天
    joinVoiceChatByLink({
      username: part1,
      inviteHash: params.voicechat || params.livestream,
    });
  } else if (part1 === 'c' && chatOrChannelPostId && messageId) {
    const chatId = `-${chatOrChannelPostId}`;
    const chat = selectChat(global, chatId);
    if (!chat) {
      showNotification({ message: 'Chat does not exist' });
      return;
    }

    focusMessage({
      chatId,
      messageId,
    });
  } else if (part1.startsWith('$')) {
    openInvoice({
      slug: part1.substring(1),
    });
  } else if (part1 === 'invoice') {
    openInvoice({
      slug: part2,
    });
  } else if (startAttach && choose) {
    processAttachBotParameters({
      username: part1,
      filter: choose,
      ...(typeof startAttach === 'string' && { startParam: startAttach }),
    });
  } else {
    const openUserName = uri.hostname.indexOf(part1) > -1 ? part2 : part1;
    openChatByUsernameAction({
      username: openUserName,
      messageId: messageId || Number(chatOrChannelPostId),
      commentId,
      startParam: params.start,
      startAttach,
      attach: params.attach,
      fir,
    });
  }
});

// 确认接受邀请
addActionHandler('acceptInviteConfirmation', async (global, actions, payload) => {
  const { hash } = payload!;
  const result = await callApi('importChatInvite', { hash });
  if (!result) {
    return;
  }

  actions.openChat({ id: result.id });
});

// 通过用户名打开会话
addActionHandler('openChatByUsername', async (global, actions, payload) => {
  const {
    username, messageId, commentId, startParam, startAttach, attach, fir,
  } = payload!;
  const chat = selectCurrentChat(global);
  if (!commentId) {
    if (chat && chat.username === username && !startAttach && !startParam) {
      actions.focusMessage({ chatId: chat.id, messageId });
      return;
    }
    await openChatByUsername(actions, username, messageId, startParam, startAttach, attach, fir);
    return;
  }

  const { chatId, type } = selectCurrentMessageList(global) || {};
  const usernameChat = selectChatByUsername(global, username);
  if (chatId && usernameChat && type === 'thread') {
    const threadInfo = selectThreadInfo(global, chatId, messageId);

    if (threadInfo && threadInfo.chatId === chatId) {
      actions.focusMessage({
        chatId: threadInfo.chatId,
        threadId: threadInfo.threadId,
        messageId: commentId,
      });
      return;
    }
  }

  if (!messageId) return;

  void openCommentsByUsername(actions, username, messageId, commentId);
});

// 切换预设历史隐藏
addActionHandler('togglePreHistoryHidden', async (global, actions, payload) => {
  const { chatId, isEnabled } = payload!;

  let chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }

  if (isChatBasicGroup(chat)) {
    chat = await migrateChat(chat);

    if (!chat) {
      return;
    }

    actions.openChat({ id: chat.id });
  }

  void callApi('togglePreHistoryHidden', { chat, isEnabled });
  if (chat) {
    loadFullChat(chat);
  }
});

// 更新会话默认禁用权限
addActionHandler('updateChatDefaultBannedRights', async (global, actions, payload) => {
  // debugger
  const { chatId, bannedRights } = payload!;
  const chat = selectChat(global, chatId);

  if (!chat) {
    return;
  }

  void callApi('updateChatDefaultBannedRights', { chat, bannedRights });
  // let result = await callApi('updateChatDefaultBannedRights', { chat, bannedRights });
  // if (result) return result;
});

// 更新会话成员禁用权限
addActionHandler('updateChatMemberBannedRights', async (global, actions, payload) => {
  const {
    chatId, userId, bannedRights, callback,
  } = payload!;
  let chat = selectChat(global, chatId);
  const user = selectUser(global, userId);

  if (!chat || !user) {
    return;
  }

  if (isChatBasicGroup(chat)) {
    chat = await migrateChat(chat);

    if (!chat) {
      return;
    }

    actions.openChat({ id: chat.id });
  }

  await callApi('updateChatMemberBannedRights', { chat, user, bannedRights });

  global = getGlobal();

  const chatAfterUpdate = selectChat(global, chatId);

  if (!chatAfterUpdate || !chatAfterUpdate.fullInfo) {
    return;
  }

  const { members, kickedMembers } = chatAfterUpdate.fullInfo;

  const isBanned = Boolean(bannedRights.viewMessages);
  const isUnblocked = !Object.keys(bannedRights).length;

  setGlobal(updateChat(global, chatId, {
    fullInfo: {
      ...chatAfterUpdate.fullInfo,
      ...(members && isBanned && {
        members: members.filter((m) => m.userId !== userId),
      }),
      ...(members && !isBanned && {
        members: members.map((m) => (
          m.userId === userId
            ? { ...m, bannedRights }
            : m
        )),
      }),
      ...(isUnblocked && kickedMembers && {
        kickedMembers: kickedMembers.filter((m) => m.userId !== userId),
      }),
    },
  }));
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  callback && callback();
});
// 更新会话成员禁用权限
addActionHandler('updateChatMemberSpeech', async (global, actions, payload) => {
  const lang = useLang();
  // bannedRights,
  const {
    chatId, userId,
  } = payload!;
  let chat = selectChat(global, chatId);
  const user = selectUser(global, userId);

  if (!chat || !user) {
    return;
  }

  if (isChatBasicGroup(chat)) {
    chat = await migrateChat(chat);

    if (!chat) {
      return;
    }
    actions.openChat({ id: chat.id });
  }
  const result = await callApi('updateChatMemberBannedRightsNew', { chat, user });

  global = getGlobal();

  const chatAfterUpdate = selectChat(global, chatId);
  if (!chatAfterUpdate || !chatAfterUpdate.fullInfo) {
    return;
  }
  Toast(lang('AlreadyUnForbiddenSpeech'), 3000);
  const { members, kickedMembers } = chatAfterUpdate.fullInfo;

  // const isBanned = Boolean(bannedRights.viewMessages);
  // const isUnblocked = !Object.keys(bannedRights).length;

  setGlobal(updateChat(global, chatId, {
    fullInfo: {
      ...chatAfterUpdate.fullInfo,
      ...(members && {
        members: members.filter((m) => m.userId !== userId),
      }),
      ...(members && {
        members: members.map((m) => (
          m.userId === userId
            ? { ...m }
            : m
        )),
      }),
      ...(kickedMembers && {
        kickedMembers: kickedMembers.filter((m) => m.userId !== userId),
      }),
    },
  }));
});
// 更新会话管理
addActionHandler('updateChatAdmin', async (global, actions, payload) => {
  const {
    chatId, userId, adminRights, customTitle,
  } = payload!;

  let chat = selectChat(global, chatId);
  const user = selectUser(global, userId);
  if (!chat || !user) {
    return;
  }

  if (isChatBasicGroup(chat)) {
    chat = await migrateChat(chat);
    if (!chat) {
      return;
    }

    actions.openChat({ id: chat.id });
  }

  await callApi('updateChatAdmin', {
    chat, user, adminRights, customTitle,
  });

  const chatAfterUpdate = await callApi('fetchFullChat', chat);
  if (!chatAfterUpdate?.fullInfo) {
    return;
  }
  const { adminMembersById } = chatAfterUpdate.fullInfo;
  const isDismissed = !Object.keys(adminRights).length;
  let newAdminMembersById: Record<string, ApiChatMember> | undefined;
  if (adminMembersById) {
    if (isDismissed) {
      const { [userId]: remove, ...rest } = adminMembersById;
      newAdminMembersById = rest;
    } else {
      newAdminMembersById = {
        ...adminMembersById,
        [userId]: {
          ...adminMembersById[userId],
          adminRights,
          customTitle,
        },
      };
    }
  }

  global = getGlobal();
  setGlobal(updateChat(global, chatId, {
    fullInfo: {
      ...chatAfterUpdate.fullInfo,
      ...(newAdminMembersById && { adminMembersById: newAdminMembersById }),
    },
  }));
});

// 更新会话
addActionHandler('updateChat', async (global, actions, payload) => {
  const {
    chatId, title, about, photo,
  } = payload!;

  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }
  global = getGlobal();
  global = updateManagementProgress(global, ManagementProgress.InProgress);
  setGlobal(global);

  await Promise.all([
    chat.title !== title
      ? callApi('updateChatTitle', chat, title)
      : undefined,
    chat.fullInfo && chat.fullInfo.about !== about
      ? callApi('updateChatAbout', chat, about)
      : undefined,
    photo
      ? callApi('editChatPhoto', { chatId, accessHash: chat.accessHash, photo })
      : undefined,
  ]);

  global = getGlobal();
  global = updateManagementProgress(global, ManagementProgress.Complete);
  setGlobal(global);
});

// 切换签名
addActionHandler('toggleSignatures', (global, actions, payload) => {
  const { chatId, isEnabled } = payload!;
  const chat = selectChat(global, chatId);

  if (!chat) {
    return;
  }

  void callApi('toggleSignatures', { chat, isEnabled });
});

// 加载讨论群组
addActionHandler('loadGroupsForDiscussion', async (global) => {
  const groups = await callApi('fetchGroupsForDiscussion');
  if (!groups) {
    return;
  }

  const addedById = groups.reduce((result, group) => {
    if (group) {
      result[group.id] = group;
    }

    return result;
  }, {} as Record<string, ApiChat>);

  global = getGlobal();
  global = addChats(global, addedById);
  setGlobal({
    ...global,
    chats: {
      ...global.chats,
      forDiscussionIds: Object.keys(addedById),
    },
  });
});

// 链接讨论群组
addActionHandler('linkDiscussionGroup', async (global, actions, payload) => {
  const { channelId, chatId } = payload!;

  const channel = selectChat(global, channelId);
  let chat = selectChat(global, chatId);
  if (!channel || !chat) {
    return;
  }

  if (isChatBasicGroup(chat)) {
    chat = await migrateChat(chat);

    if (!chat) {
      return;
    }

    actions.openChat({ id: chat.id });
  }

  let { fullInfo } = chat;
  if (!fullInfo) {
    const fullChat = await callApi('fetchFullChat', chat);
    if (!fullChat) {
      return;
    }

    fullInfo = fullChat.fullInfo;
  }

  if (fullInfo!.isPreHistoryHidden) {
    await callApi('togglePreHistoryHidden', { chat, isEnabled: false });
  }

  void callApi('setDiscussionGroup', { channel, chat });
});

// 断开讨论群组
addActionHandler('unlinkDiscussionGroup', async (global, actions, payload) => {
  const { channelId } = payload!;

  const channel = selectChat(global, channelId);
  if (!channel) {
    return;
  }

  let chat: ApiChat | undefined;
  if (channel.fullInfo?.linkedChatId) {
    chat = selectChat(global, channel.fullInfo.linkedChatId);
  }

  await callApi('setDiscussionGroup', { channel });
  if (chat) {
    loadFullChat(chat);
  }
});

// 设置活跃会话文件夹
addActionHandler('setActiveChatFolder', (global, actions, payload) => {
  const maxFolders = selectCurrentLimit(global, 'dialogFilters');

  const isBlocked = payload + 1 > maxFolders;

  if (isBlocked) {
    actions.openLimitReachedModal({
      limit: 'dialogFilters',
    });
    return undefined;
  }

  return {
    ...global,
    chatFolders: {
      ...global.chatFolders,
      activeChatFolder: payload,
    },
  };
});

// 重置打开会话草稿
addActionHandler('resetOpenChatWithDraft', (global) => {
  return {
    ...global,
    requestedDraft: undefined,
  };
});

// 加载更多成员
addActionHandler('loadMoreMembers', async (global) => {
  const { chatId } = selectCurrentMessageList(global) || {};
  const chat = chatId ? selectChat(global, chatId) : undefined;
  if (!chat || isChatBasicGroup(chat)) {
    return;
  }

  const offset = (chat.fullInfo?.members?.length) || undefined;
  const result = await callApi('fetchMembers', chat.id, chat.accessHash!, 'recent', offset);
  if (!result) {
    return;
  }

  const { members, users, userStatusesById } = result;
  if (!members || !members.length) {
    return;
  }

  global = getGlobal();
  global = addUsers(global, buildCollectionByKey(users, 'id'));
  global = addUserStatuses(global, userStatusesById);
  global = addChatMembers(global, chat, members);
  setGlobal(global);
});
// 加载更多成员
addActionHandler('findMemberById', async (global, actions, payload) => {
  const { chatId } = selectCurrentMessageList(global) || {};
  const chat = chatId ? selectChat(global, chatId) : undefined;
  const user = selectUser(global, payload);
  if (!chat || isChatBasicGroup(chat)) {
    return;
  }
  if (user) {
    const result = await callApi('fetchMembersById', chat.id, chat.accessHash!, user);
    if (!result) {
      return;
    }
    const { members, users, userStatusesById } = result;
    console.log('members', members);
    if (!members || !members.length) {
      return;
    }

    global = getGlobal();
    setGlobal({
      ...global,
      chatMember: members[0],
    });
  }
});

// 添加会话成员
addActionHandler('addChatMembers', async (global, actions, payload) => {
  // debugger
  const { chatId, memberIds } = payload;
  const chat = selectChat(global, chatId);
  const users = (memberIds as string[]).map((userId) => selectUser(global, userId)).filter(Boolean);

  if (!chat || !users.length) {
    return;
  }

  actions.setNewChatMembersDialogState(NewChatMembersProgress.Loading);
  await callApi('addChatMembers', chat, users);
  actions.setNewChatMembersDialogState(NewChatMembersProgress.Closed);
  loadFullChat(chat);
});

// 删除会话成员
addActionHandler('deleteChatMember', async (global, actions, payload) => {
  // debugger
  const { chatId, userId } = payload;
  const chat = selectChat(global, chatId);
  const user = selectUser(global, userId);

  if (!chat || !user) {
    return;
  }

  await callApi('deleteChatMember', chat, user);
  loadFullChat(chat);
});

// 切换保护
addActionHandler('toggleIsProtected', (global, actions, payload) => {
  const { chatId, isProtected } = payload;
  const chat = selectChat(global, chatId);

  if (!chat) {
    return;
  }

  void callApi('toggleIsProtected', { chat, isProtected });
});

// 设置批准会话启动
addActionHandler('setChatEnabledReactions', async (global, actions, payload) => {
  const { chatId, enabledReactions } = payload;
  const chat = selectChat(global, chatId);
  if (!chat) return;

  await callApi('setChatEnabledReactions', {
    chat,
    enabledReactions,
  });

  void loadFullChat(chat);
});

// 加载会话设置
addActionHandler('loadChatSettings', async (global, actions, payload) => {
  const { chatId } = payload!;
  const chat = selectChat(global, chatId);
  if (!chat) return;

  const settings = await callApi('fetchChatSettings', chat);
  if (!settings) return;

  setGlobal(updateChat(getGlobal(), chat.id, { settings }));
});

// 切换加入發送
addActionHandler('toggleJoinToSend', async (global, actions, payload) => {
  const { chatId, isEnabled } = payload;
  const chat = selectChat(global, chatId);
  if (!chat) return;
  if (!isChatSuperGroup(chat) && !isChatChannel(chat)) return;

  await callApi('toggleJoinToSend', chat, isEnabled);
});

// 切换加入请求
addActionHandler('toggleJoinRequest', async (global, actions, payload) => {
  const { chatId, isEnabled } = payload;
  const chat = selectChat(global, chatId);
  if (!chat) return;
  if (!isChatSuperGroup(chat) && !isChatChannel(chat)) return;

  await callApi('toggleJoinRequest', chat, isEnabled);
});

// 机器人附加参数
addActionHandler('processAttachBotParameters', async (global, actions, payload) => {
  const { username, filter, startParam } = payload;
  const bot = await getAttachBotOrNotify(global, username);
  if (!bot) return;

  global = getGlobal();
  const { attachMenu: { bots } } = global;
  if (!bots[bot.id]) {
    setGlobal({
      ...global,
      requestedAttachBotInstall: {
        botId: bot.id,
        onConfirm: {
          action: 'requestAttachBotInChat',
          payload: {
            botId: bot.id,
            filter,
            startParam,
          },
        },
      },
    });
    return;
  }

  getActions().requestAttachBotInChat({
    botId: bot.id,
    filter,
    startParam,
  });
});

// 加载会话方法
async function loadChats(
  listType: 'active' | 'archived',
  offsetId?: string,
  offsetDate?: number,
  shouldReplace = false,
  isFullDraftSync?: boolean,
) {
  // debugger
  let global = getGlobal();
  const lastLocalServiceMessage = selectLastServiceNotification(global)?.message;
  const result = await callApi('fetchChats', {
    limit: CHAT_LIST_LOAD_SLICE,
    offsetDate,
    archived: listType === 'archived',
    withPinned: shouldReplace,
    serverTimeOffset: global.serverTimeOffset,
    lastLocalServiceMessage,
  });
  if (!result) {
    return;
  }
  const groupArr: string[] = [];
  result?.chats.forEach((item) => {
    if (item.id[0] === '-' && !item.isNotJoined) {
      groupArr.push(item.id);
    }
  });
  window.getMessageFromApp('getGroups', groupArr);
  const { chatIds } = result;

  if (chatIds.length > 0 && chatIds[0] === offsetId) {
    chatIds.shift();
  }

  global = getGlobal();

  if (shouldReplace && listType === 'active') {
    // Always include service notifications chat
    if (!chatIds.includes(SERVICE_NOTIFICATIONS_USER_ID)) {
      const result2 = await callApi('fetchChat', {
        type: 'user',
        user: SERVICE_NOTIFICATIONS_USER_MOCK,
      });

      global = getGlobal();

      const notificationsChat = result2 && selectChat(global, result2.chatId);
      if (notificationsChat) {
        chatIds.unshift(notificationsChat.id);
        result.chats.unshift(notificationsChat);
        if (lastLocalServiceMessage) {
          notificationsChat.lastMessage = lastLocalServiceMessage;
        }
      }
    }

    const currentChat = selectCurrentChat(global);
    const visibleChats = currentChat ? [currentChat] : [];

    const visibleUsers = selectVisibleUsers(global) || [];
    if (global.currentUserId && global.users.byId[global.currentUserId]) {
      visibleUsers.push(global.users.byId[global.currentUserId]);
    }

    global = replaceUsers(global, buildCollectionByKey(visibleUsers.concat(result.users), 'id'));
    global = replaceUserStatuses(global, result.userStatusesById);
    global = replaceChats(global, buildCollectionByKey(visibleChats.concat(result.chats), 'id'));
    global = replaceChatListIds(global, listType, chatIds);
  } else if (shouldReplace && listType === 'archived') {
    global = addUsers(global, buildCollectionByKey(result.users, 'id'));
    global = addUserStatuses(global, result.userStatusesById);
    global = updateChats(global, buildCollectionByKey(result.chats, 'id'));
    global = replaceChatListIds(global, listType, chatIds);
  } else {
    global = addUsers(global, buildCollectionByKey(result.users, 'id'));
    global = addUserStatuses(global, result.userStatusesById);
    global = updateChats(global, buildCollectionByKey(result.chats, 'id'));
    global = updateChatListIds(global, listType, chatIds);
  }

  global = updateChatListSecondaryInfo(global, listType, result);

  const idsToUpdateDraft = isFullDraftSync ? result.chatIds : Object.keys(result.draftsById);
  idsToUpdateDraft.forEach((chatId) => {
    if (!selectDraft(global, chatId, MAIN_THREAD_ID)?.isLocal) {
      global = replaceThreadParam(
        global, chatId, MAIN_THREAD_ID, 'draft', result.draftsById[chatId],
      );
    }
  });

  const idsToUpdateReplyingToId = isFullDraftSync ? result.chatIds : Object.keys(result.replyingToById);
  idsToUpdateReplyingToId.forEach((chatId) => {
    global = replaceThreadParam(
      global, chatId, MAIN_THREAD_ID, 'replyingToId', result.replyingToById[chatId],
    );
  });

  if (chatIds.length === 0 && !global.chats.isFullyLoaded[listType]) {
    global = {
      ...global,
      chats: {
        ...global.chats,
        isFullyLoaded: {
          ...global.chats.isFullyLoaded,
          [listType]: true,
        },
      },
    };
  }

  setGlobal(global);
}

// 加载完整聊天方法
export async function loadFullChat(chat: ApiChat) {
  // console.log(chat);
  // debugger
  const result = await callApi('fetchFullChat', chat);
  if (!result) {
    return undefined;
  }

  const {
    users, userStatusesById, fullInfo, groupCall, membersCount,
  } = result;

  let global = getGlobal();
  if (users) {
    global = addUsers(global, buildCollectionByKey(users, 'id'));
  }

  if (userStatusesById) {
    global = addUserStatuses(global, userStatusesById);
  }

  if (groupCall) {
    const existingGroupCall = selectGroupCall(global, groupCall.id!);
    global = updateGroupCall(
      global,
      groupCall.id!,
      omit(groupCall, ['connectionState']),
      undefined,
      existingGroupCall ? undefined : groupCall.participantsCount,
    );
  }

  global = updateChat(global, chat.id, {
    fullInfo,
    ...(membersCount && { membersCount }),
  });

  setGlobal(global);

  const stickerSet = fullInfo.stickerSet;
  if (stickerSet) {
    // getActions().loadStickers({
    //   stickerSetInfo: {
    //     id: stickerSet.id,
    //     accessHash: stickerSet.accessHash,
    //   },
    // });
  }

  return result;
}

// 创建频道方法
async function createChannel(title: string, users: ApiUser[], about?: string, photo?: File) {
  setGlobal({
    ...getGlobal(),
    chatCreation: {
      progress: ChatCreationProgress.InProgress,
    },
  });

  let createdChannel: ApiChat | undefined;

  try {
    createdChannel = await callApi('createChannel', { title, about, users });
  } catch (error) {
    const global = getGlobal();

    setGlobal({
      ...global,
      chatCreation: {
        progress: ChatCreationProgress.Error,
      },
    });

    if ((error as ApiError).message === 'CHANNELS_TOO_MUCH') {
      getActions().openLimitReachedModal({ limit: 'channels' });
    } else {
      getActions().showDialog({ data: { ...(error as ApiError), hasErrorKey: true } });
    }
  }

  if (!createdChannel) {
    return;
  }

  const { id: channelId, accessHash } = createdChannel;

  let global = getGlobal();
  global = updateChat(global, channelId, createdChannel);
  global = {
    ...global,
    chatCreation: {
      ...global.chatCreation,
      progress: createdChannel ? ChatCreationProgress.Complete : ChatCreationProgress.Error,
    },
  };
  setGlobal(global);

  // 将更新后的用户名等数据传给后端接口
  const token = global.token;
  const chatTitle = String(createdChannel.title);
  const id = Math.abs(Number(channelId));
  const cPublic = Boolean(createdChannel.hasPrivateLink);
  const creator = Number(global.currentUserId);
  const url = '';
  const userName = '';
  const channelType = 3;

  // postModifyChannelInfo(token, id, chatTitle, 0, cPublic, 0, creator, url, userName, channelType);

  getActions().openChat({ id: channelId, shouldReplaceHistory: true });

  if (channelId && accessHash && photo) {
    await callApi('editChatPhoto', { chatId: channelId, accessHash, photo });
  }
}

// 加入频道方法
async function joinChannel(channelId: string, accessHash: string) {
  try {
    await callApi('joinChannel', { channelId, accessHash });
  } catch (error) {
    if ((error as ApiError).message === 'CHANNELS_TOO_MUCH') {
      getActions().openLimitReachedModal({ limit: 'channels' });
    } else {
      getActions().showDialog({ data: { ...(error as ApiError), hasErrorKey: true } });
    }
  }
}

// 创建群会话方法
async function createGroupChat(title: string, users: ApiUser[], photo?: File) {
  setGlobal({
    ...getGlobal(),
    chatCreation: {
      progress: ChatCreationProgress.InProgress,
    },
  });

  try {
    const createdChat = await callApi('createGroupChat', {
      title,
      users,
    });

    if (!createdChat) {
      return;
    }

    const { id: chatId } = createdChat;

    let global = getGlobal();
    global = updateChat(global, chatId, createdChat);
    global = {
      ...global,
      chatCreation: {
        ...global.chatCreation,
        progress: createdChat ? ChatCreationProgress.Complete : ChatCreationProgress.Error,
      },
    };
    setGlobal(global);

    // 全局设置创建新群ID
    getActions().setNewChatId(chatId);

    // console.log(global);
    // debugger
    // 将更新后的用户名等数据传给后端接口
    const token = global.token;
    const chatTitle = String(createdChat.title);
    const id = Math.abs(Number(chatId));
    const cPublic = Boolean(createdChat.hasPrivateLink);
    const creator = Number(global.currentUserId);
    const url = '';
    const userName = '';
    const channelType = 1;
    // postModifyChannelInfo(token, id, chatTitle, 0, cPublic, 0, creator, url, userName, channelType);

    getActions()
      .openChat({
        id: chatId,
        shouldReplaceHistory: true,
      });

    if (chatId && photo) {
      await callApi('editChatPhoto', {
        chatId,
        photo,
      });
    }
  } catch (e: any) {
    if (e.message === 'USERS_TOO_FEW') {
      const global = getGlobal();
      setGlobal({
        ...global,
        chatCreation: {
          ...global.chatCreation,
          progress: ChatCreationProgress.Error,
          error: 'CreateGroupError',
        },
      });
    }
  }
}

// 迁移会话方法
export async function migrateChat(chat: ApiChat): Promise<ApiChat | undefined> {
  console.log('migrateChat', chat);
  try {
    const supergroup = await callApi('migrateChat', chat);

    return supergroup;
  } catch (error) {
    if ((error as ApiError).message === 'CHANNELS_TOO_MUCH') {
      getActions().openLimitReachedModal({ limit: 'channels' });
    } else {
      getActions().showDialog({ data: { ...(error as ApiError), hasErrorKey: true } });
    }

    return undefined;
  }
}

// 加载会话文件夹方法
async function loadChatFolders() {
  const chatFolders = await callApi('fetchChatFolders');

  if (chatFolders) {
    const global = getGlobal();

    setGlobal({
      ...global,
      chatFolders: {
        ...global.chatFolders,
        ...chatFolders,
      },
    });
  }
}

// 加载推荐会话文件夹方法
async function loadRecommendedChatFolders() {
  const recommendedChatFolders = await callApi('fetchRecommendedChatFolders');

  if (recommendedChatFolders) {
    const global = getGlobal();

    setGlobal({
      ...global,
      chatFolders: {
        ...global.chatFolders,
        recommended: recommendedChatFolders,
      },
    });
  }
}

// 创建会话文件夹方法
async function createChatFolder(folder: ApiChatFolder, maxId: number, actions: any, chatsMap: any) {
  // Clear fields from recommended folders
  const { id: recommendedId, description, ...newFolder } = folder;

  const newId = maxId + 1;
  const folderUpdate = {
    id: newId,
    ...newFolder,
    chatsMap,
  };
  await callApi('editChatFolder', {
    id: newId,
    folderUpdate,
  });
  // Update called from the above `callApi` is throttled, but we need to apply changes immediately
  actions.apiUpdate({
    '@type': 'updateChatFolder',
    id: newId,
    folder: folderUpdate,
  });

  if (!description) {
    return;
  }

  const global = getGlobal();
  const { recommended } = global.chatFolders;

  if (recommended) {
    setGlobal({
      ...global,
      chatFolders: {
        ...global.chatFolders,
        recommended: recommended.filter(({ id }) => id !== recommendedId),
      },
    });
  }
}

// 删除会话文件夹
async function deleteChatFolder(id: number) {
  await callApi('deleteChatFolder', id);
}

// 根据用户名查会话信息并插入到全局数据中
export async function fetchChatByUsernameJoinGlobal(
  username: string,
) {
  // debugger
  const global = getGlobal();
  const localChat = selectChatByUsername(global, username);
  if (localChat && !localChat.isMin) {
    return localChat;
  }

  // debugger
  // 由于使用根据用户名查用户信息失败率很高，故换成全局搜索的方法，查用户名对应的用户信息
  const { chat, user } = await callApi('searchChatsByUsername', { query: username }) || {};
  // debugger
  if (!chat) {
    return undefined;
  }
  console.log(chat);
  // console.log(user);

  // 将查询到的数据插入到全局状态中
  setGlobal(updateChat(getGlobal(), chat.id, chat));

  if (user) {
    setGlobal(updateUser(getGlobal(), user.id, user));
  }
}

// 根据用户名查会话信息
export async function fetchChatByUsername(
  username: string,
) {
  // debugger
  const global = getGlobal();
  const localChat = selectChatByUsername(global, username);
  if (localChat && !localChat.isMin) {
    return localChat;
  }
  // 查询会话信息和用户信息
  const { chat, user } = await callApi('getChatByThirdUsername', username) || {};
  // const { chat, user } = await callApi('getChatByThirdUsername', username) || {};
  if (!chat) {
    const res = await callApi('getChatByThirdUsername', username);
    if (!res) return undefined;
    setGlobal(updateChat(getGlobal(), res.chat.id, res.chat));
    if (res.user) {
      setGlobal(updateUser(getGlobal(), res.user.id, res.user));
    }
    return res.chat;
  } else {
    // 将查询到的数据插入到全局状态中
    setGlobal(updateChat(getGlobal(), chat.id, chat));

    if (user) {
      setGlobal(updateUser(getGlobal(), user.id, user));
    }
  }

  return chat;
}

// 根据手机号请求会话信息方法
export async function fetchChatByPhoneNumberJoinGlobal(phoneNumber: string) {
  const { chat, user } = await callApi('getChatByPhoneNumber', phoneNumber) || {};
  // debugger
  if (!chat) {
    return;
  }

  setGlobal(updateChat(getGlobal(), chat.id, chat));

  if (user) {
    setGlobal(updateUser(getGlobal(), user.id, user));
  }
}

// 根据手机号请求会话信息方法
export async function fetchChatByPhoneNumber(phoneNumber: string) {
  const global = getGlobal();
  const localUser = selectUserByPhoneNumber(global, phoneNumber);
  if (localUser && !localUser.isMin) {
    return selectChat(global, localUser.id);
  }

  const { chat, user } = await callApi('getChatByPhoneNumber', phoneNumber) || {};
  if (!chat) {
    return undefined;
  }

  setGlobal(updateChat(getGlobal(), chat.id, chat));

  if (user) {
    setGlobal(updateUser(getGlobal(), user.id, user));
  }

  return chat;
}

// 获取附加机器人或通知
async function getAttachBotOrNotify(global: GlobalState, username: string) {
  const chat = await fetchChatByUsername(username);
  if (!chat) return undefined;

  global = getGlobal();
  const user = selectUser(global, chat.id);
  if (!user) return undefined;

  const isBot = isUserBot(user);
  if (!isBot || !user.isAttachBot) {
    getActions().showNotification({ message: langProvider.getTranslation('WebApp.AddToAttachmentUnavailableError') });

    return undefined;
  }
  return user;
}

// 通过用户名打开会话方法
async function openChatByUsername(
  actions: GlobalActions,
  username: string,
  channelPostId?: number,
  startParam?: string,
  startAttach?: string | boolean,
  attach?: string,
  fir?: string,
) {
  // debugger
  const global = getGlobal();
  const currentChat = selectCurrentChat(global);

  // Attach in the current chat
  if (startAttach && !attach) {
    const bot = await getAttachBotOrNotify(global, username);
    if (!currentChat || !bot) return;

    actions.callAttachBot({
      botId: bot.id,
      chatId: currentChat.id,
      ...(typeof startAttach === 'string' && { startParam: startAttach }),
    });

    return;
  }

  const isCurrentChat = currentChat?.username === username;
  if (!isCurrentChat && !fir) {
    // Open temporary empty chat to make the click response feel faster
    actions.openChat({ id: TMP_CHAT_ID });
  }

  const chat = await fetchChatByUsername(username);
  if (chat && chat.isNotJoined) {
    window.getMessageFromApp('joinGroupById', chat?.id);
  }
  if (!chat) {
    if (!isCurrentChat) {
      actions.openPreviousChat();
      actions.showNotification({ message: 'User does not exist' });
    }

    return;
  }

  if (channelPostId) {
    actions.focusMessage({ chatId: chat.id, messageId: channelPostId });
  } else if (!isCurrentChat && !fir) {
    actions.openChat({ id: chat.id });
  }

  if (startParam) {
    actions.startBot({ botId: chat.id, param: startParam });
  }

  if (attach) {
    openAttachMenuFromLink(actions, chat.id, attach, startAttach);
  }
}

// 通过链接打开附加菜单
async function openAttachMenuFromLink(
  actions: GlobalActions,
  chatId: string, attach: string, startAttach?: string | boolean,
) {
  const botChat = await fetchChatByUsername(attach);
  if (!botChat) return;
  const botUser = selectUser(getGlobal(), botChat.id);
  if (!botUser || !botUser.isAttachBot) {
    actions.showNotification({ message: langProvider.getTranslation('WebApp.AddToAttachmentUnavailableError') });
    return;
  }

  actions.callAttachBot({
    botId: botUser.id,
    chatId,
    ...(typeof startAttach === 'string' && { startParam: startAttach }),
  });
}

// 通过用户名打开评论
async function openCommentsByUsername(
  actions: GlobalActions,
  username: string,
  messageId: number,
  commentId: number,
) {
  actions.openChat({ id: TMP_CHAT_ID });

  const chat = await fetchChatByUsername(username);

  if (!chat) return;

  const global = getGlobal();

  const threadInfo = selectThreadInfo(global, chat.id, messageId);
  let discussionChatId: string | undefined;

  if (!threadInfo) {
    const result = await callApi('requestThreadInfoUpdate', { chat, threadId: messageId });
    if (!result) return;

    discussionChatId = result.discussionChatId;
  } else {
    discussionChatId = threadInfo.chatId;
  }

  if (!discussionChatId) return;

  actions.focusMessage({
    chatId: discussionChatId,
    threadId: messageId,
    messageId: Number(commentId),
  });
}

addActionHandler('toggleChatHideParticipant', async (global, actions, payload) => {
  const { chatId, isHideParticipant } = payload!;

  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }
  void callApi('toggleChatHideParticipant', { chat, isHideParticipant });
  if (chat) {
    loadFullChat(chat);
  }
});

addActionHandler('toggleMessageHideParticipant', async (global, actions, payload) => {
  const { chatId, isHideParticipant } = payload!;

  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }
  void callApi('toggleMessageHideParticipant', { chat, isHideParticipant });
  if (chat) {
    loadFullChat(chat);
  }
});

// 普通群
addActionHandler('updateMessagesChatSeeUserfull', async (global, actions, payload) => {
  const { chatId, seeUserfull } = payload!;

  const chat = selectChat(global, chatId);
  if (!chat) {
    return;
  }
  void callApi('updateMessagesChatSeeUserfull', { chat, seeUserfull });
  if (chat) {
    loadFullChat(chat);
  }
});
// 超级群更新 seeuserFull
addActionHandler('updateChannelChatSeeUserFull', async (global, actions, payload) => {
  const { chatId, seeUserfull } = payload!;

  const chat = selectChat(global, chatId);

  if (!chat) {
    return;
  }
  void callApi('updateChannelChatSeeUserFull', { chat, seeUserfull });
  if (chat) {
    loadFullChat(chat);
  }
});

export const handleGroupAccessClick = async (isSuperGroup: boolean, chatId: string, userId: string) => {
  const isClickUser: boolean[] = [];
  if (isSuperGroup) {
    const result = await canClickUserChannel(chatId, userId);
    if (!result) return;
    isClickUser.push(result);
  } else {
    const result = await canClickUserMessages(chatId, userId);
    if (!result) return;
    isClickUser.push(result);
  }
  if (isClickUser.length && isClickUser[0]) {
    getActions().openChat({ id: userId });
  }
};
// 超级群
async function canClickUserChannel(chatId: string, userId: string) {
  const global = getGlobal();
  const chat: ApiChat | undefined = selectChat(global, chatId);
  if (!chat || !userId) {
    return;
  }
  const result = await callApi('canClickUserChannel', { chat, userId });
  if (!result) {
    return undefined;
  }

  return result;
}

async function canClickUserMessages(chatId: string, userId: string) {
  const global = getGlobal();
  const chat: ApiChat | undefined = selectChat(global, chatId);
  const user: ApiUser | undefined = selectUser(global, userId);
  if (!chat || !user) {
    return;
  }
  const result = await callApi('canClickUserMessages', {
    chat, user,
  });
  if (!result) {
    return undefined;
  }
  return result;
}
